<template>
	<div id="cs-header-menu" class="cs-header-menu-block cs-block cs-no-padding">
		<div class="cs-base-block">
			<CSMenu
				:menuStyle="data.style"
				:logo="data.logo"
				:links="data.links"
				:menu="menu"
			/>
		</div>
	</div>
</template>

<script>
import { mapActions } from "vuex"
import {computed} from '../libs/common-fn';
export default {
	name: "Menu",
	components: {
		CSMenu: () => import("../components/CSMenu.vue"),
	},
	data() {
		return {
			menu: {},
		}
	},
	props: {
		data: {
			type: Object,
			default: () => {},
		},
	},
	methods: {
		...mapActions("menu", ["retrieve"]),
	},
	mounted() {
		this.retrieve({ id: this.data.menu, l1: 4, l2: 3, l3: 0, l4: 0 }).then(
			menu => {
				if (menu) this.menu = Object.values(menu)
			}
		)
	},
	computed: computed('HeaderMenu')
}
</script>

<style lang="scss" scoped>
@import "../styles/main.scss";
.cs-header-menu-block {
	z-index: 101;
    border-bottom: 1px solid #ccc;
}

.cs-header-menu-block.cs-block .cs-nav-primary-container::after {
    display: none !important;
}
::v-deep {
	.cs-nav-primary-container {
		background-color: #fff;

		.cs-interactions {
            row-gap: 30px !important;
            flex-wrap: wrap;
            @media (max-width: 1199px) {
                justify-content: center !important;
            }
            .cs-button-container {
                @media (max-width: 275px) {
                    margin-right: 0 !important;
                }
            }
			span.cs-button, a.cs-button {
				background: $theme_color !important;
				border: 1px solid $theme_color;
				color: $secondary_text_color !important;
				font-weight: 400;
                border-radius: 0 !important;
                cursor: pointer;
                padding: 12px 15px !important;

				&:hover {
					border: 1px solid $theme_color;
					background-color: transparent !important;
					color: $secondary_text_color !important;
				}
			}
		}
		.cs-nav-primary-links {
            @media (max-width: 1199px) {
                margin-top: 10px;
                align-items: center !important;
            }
			span:hover,
			span.active {
				background: #CCD5AE !important;
			}
			a,
			span {
				color: #333 !important;
                border-radius: 0 !important;
                font-size: 16px !important;
				svg {
					path {
						fill: #333 !important;
					}
				}
                @media (max-width: 1199px) {
                    width: fit-content !important;
                    min-width: 170px !important;
                }
			}
			a:hover,
			a.active {
				background: #CCD5AE !important;
			}
            .single-dropdown {
                box-shadow: none !important;
                top: 62px !important;
                border: 1px solid #ccc;
                border-radius: 0 !important;
                border-top: 0;
            }
		}
	}
    .cs-nav-secondary-container {
		span.cs-menu-description,
		.cs-secondary-menu div {
			font-size: 16px;
		}

        .cs-secondary-menu div,
        .cs-links div {
            border-radius: 0 !important;
        }
	}
    .navbar-toggler {
        &::after, &::before, span {
            background-color: #000 !important;
        }
    }
    .cs-menu-overlay {
        .cs-nav-overlay {
            background-color: #fff !important;
            left: 0;
            .cs-nav-primary-links {
                margin-top: 0px;
                > div {
                    display: flex;
                    align-items: center;
                    flex-direction: column;
                    .cs-nested-link {
                        width: fit-content !important;
                        min-width: 170px !important;
                        span, a {
                            font-size: 16px !important;
                            font-weight: 400 !important;
                            margin-bottom: 3px;
                        }
                    }
                }
            }
        }
    }
}
</style>
